<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0">
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100">
        <!--begin::Navs-->
        <div class="h3">
          Daftar {{ title }}
          <i
            @click="resetData"
            class="ms-1 text-primary fs-3 bi bi-arrow-clockwise cursor-pointer"
          ></i>
        </div>

        <button
          class="btn btn-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#modal-create"
        >
          Tambah {{ title }}
        </button>
        <!--begin::Navs-->
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body border-top p-9">
      <div class="table-responsive mt-md-n5">
        <table
          class="table table-bordered table-row-bordered table-hover"
          v-if="data"
        >
          <thead>
            <tr class="fw-bolder fs-5 border-bottom">
              <th>Nama</th>
              <th>Logo</th>
              <th>Warna</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in data"
              :key="i"
              @click="getDetail(item)"
              class="cursor-pointer"
            >
              <td>{{ item.name }}</td>
              <td>
                <img
                  :src="path + item.logo"
                  style="max-height: 100px"
                  class="img-fluid"
                  alt="logo"
                />
              </td>
              <td>
                <div
                  class="rounded-circle"
                  style="width: 50px; height: 50px"
                  :style="{ backgroundColor: item.colour }"
                ></div>
              </td>
              <td>
                <button
                  @click="destroyData(item)"
                  class="btn btn-sm btn-danger"
                >
                  <i class="bi bi-trash"></i> Hapus Data
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="text-center fw-bolder fs-4">Belum ada data</div>
      </div>
      <div class="row" v-if="data">
        <div
          class="
            col-sm-12 col-md-5
            d-flex
            align-items-center
            justify-content-center justify-content-md-start
          "
        >
          <div class="dataTables_length" id="kt_customers_table_length">
            <label>
              <select
                name="kt_customers_table_length"
                class="form-select form-select-sm form-select-solid"
                @change="changeLimit"
              >
                <option :selected="limit == 10" value="10">10</option>
                <option :selected="limit == 25" value="25">25</option>
                <option :selected="limit == 50" value="50">50</option>
                <option :selected="limit == 100" value="100">100</option>
              </select>
            </label>
          </div>
        </div>
        <div
          class="
            col-sm-12 col-md-7
            d-flex
            align-items-center
            justify-content-center justify-content-md-end
          "
        >
          <paginate
            v-if="data"
            :total-data="totalPage"
            @set-rows="getRows"
            :current-row="page"
          />
        </div>
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Basic info-->

  <!-- open modal detail -->
  <button
    id="open-modal-detail"
    class="rounded btn btn-sm btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#modal-detail"
  >
    Detail Data
  </button>

  <!-- Modal Detail -->
  <div
    class="modal fade"
    id="modal-detail"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Detail {{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <Form
          id="form-edit"
          class="form"
          novalidate="novalidate"
          @submit="FormUpdate"
          :validation-schema="formEdit"
        >
          <div class="modal-body">
            <div class="row">
              <input type="hidden" readonly name="id" v-model="detail.id" />
              <div class="col-lg-4 mb-5">
                <div class="col-lg-12">
                  <label for="">Logo Partai</label><br />
                  <img
                    :src="detail.newFoto ? detail.newFoto : foto"
                    @click="changeFoto"
                    id="update-foto"
                    class="img-fluid cursor-pointer rounded-3"
                    style="max-height: 200px"
                    alt="foto"
                  />
                  <Field
                    type="file"
                    name="logo"
                    id="update-foto"
                    accept="image/*"
                    @input="preFoto"
                    class="form-control d-none"
                  />
                  <Field
                    type="hidden"
                    readonly=""
                    name="image"
                    v-model="detail.logo"
                    class="form-control"
                  />
                </div>
                <ErrorMessage
                  name="image"
                  class="fv-plugins-message-container invalid-feedback"
                ></ErrorMessage>
              </div>
              <div class="col-lg-7">
                <div class="row">
                  <div class="col-mb-6 mb-5">
                    <label for="">Nama Partai</label>
                    <Field
                      type="text"
                      name="name"
                      v-model="detail.data.name"
                      class="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="name"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-mb-6">
                    <label for="">Warna Partai</label>
                    <Field
                      type="text"
                      name="colour"
                      v-model="detail.data.colour"
                      placeholder="Masukan kode warna RGB : #009EF7"
                      class="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="colour"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                    <!-- <color-picker
                v-model:pureColor="pureColor"
                v-model:gradientColor="gradientColor"
              /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              form="form-input"
              ref="editButton"
              class="btn btn-primary"
            >
              Simpan
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <!-- Modal Tambah -->
  <div
    class="modal fade"
    id="modal-create"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Tambah {{ title }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <Form
          id="form-input"
          class="form"
          novalidate="novalidate"
          @submit="FormSubmit"
          :validation-schema="form"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-4 mb-5">
                <div class="col-lg-12">
                  <label for="">Logo Partai</label><br />
                  <img
                    :src="created.newFoto ? created.newFoto : foto"
                    @click="changeFoto"
                    id="input-preview"
                    class="img-fluid cursor-pointer rounded-3"
                    style="max-height: 200px"
                    alt="foto"
                  />
                  <Field
                    type="file"
                    name="logo"
                    id="input-foto"
                    accept="image/*"
                    @input="preFoto"
                    class="form-control d-none"
                  />
                  <Field
                    type="hidden"
                    readonly=""
                    name="image"
                    v-model="created.logo"
                    class="form-control"
                  />
                </div>
                <ErrorMessage
                  name="image"
                  class="fv-plugins-message-container invalid-feedback"
                ></ErrorMessage>
              </div>
              <div class="col-lg-7">
                <div class="row">
                  <div class="col-mb-6 mb-5">
                    <label for="">Nama Partai</label>
                    <Field
                      type="text"
                      name="name"
                      class="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="name"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                  </div>
                  <div class="col-mb-6">
                    <label for="">Warna Partai</label>
                    <Field
                      type="text"
                      name="colour"
                      placeholder="Masukan kode warna RGB : #009EF7"
                      class="form-control form-control-lg"
                    />
                    <ErrorMessage
                      name="colour"
                      class="fv-plugins-message-container invalid-feedback"
                    ></ErrorMessage>
                    <!-- <color-picker
                v-model:pureColor="pureColor"
                v-model:gradientColor="gradientColor"
              /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              form="form-input"
              ref="inputButton"
              class="btn btn-primary"
            >
              Simpan
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Paginate from "@/components/Paginate.vue";
import ApiService from "@/core/services/ApiService";
// import { ColorPicker } from "vue3-colorpicker";
// import "vue3-colorpicker/style.css";

export default defineComponent({
  components: {
    // ColorPicker
    ErrorMessage,
    Field,
    Form,
    Paginate,
  },
  setup() {
    const title = "Partai";
    const name = "Parties";
    setCurrentPageTitle(title);
    const store = useStore();
    const path = window.location.origin + "/";
    const page = computed(() => store.state.PartiesModule.page);
    const limit = computed(() => store.state.PartiesModule.limit);
    const totalPage = computed(() => store.state.PartiesModule.totalPage);
    const baseUrl = ApiService.vueInstance.axios.defaults.baseURL;

    // call data required
    store.dispatch("All" + name);

    const foto = ref(path + "other/default/blank.png");
    const created = ref({
      newFoto: "",
      logo: "",
    });
    const inputButton = ref<HTMLElement | null>(null);
    const editButton = ref<HTMLElement | null>(null);

    const form = Yup.object().shape({
      name: Yup.string().required().label("Nama"),
      image: Yup.string().required().label("Logo"),
      colour: Yup.string().required().label("Warna"),
    });

    const formEdit = Yup.object().shape({
      name: Yup.string().required().label("Nama"),
      image: Yup.string().required().label("Logo"),
      colour: Yup.string().required().label("Warna"),
    });

    const data = computed(() => store.state.PartiesModule.all);

    // method 1
    // const detail = computed(() => store.state.PartiesModule.detail);
    // method 2
    const detail = ref({ newFoto: "", logo: "", data: {} });

    // color picker items
    // const pureColor = ref({});
    // const gradientColor = ref(
    //   "linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)"
    // );

    store.commit("SET_ACTIVE_MENU", "admin.politic.parties");

    const getDetail = (item) => {
      // method 1
      // store.dispatch("detail" + name.toUpperCase(), item.id);
      // method 2
      // updated.value.newFoto = baseUrl + detail.value.logo;
      detail.value.newFoto = path + item.logo;
      detail.value.logo = item.logo;
      detail.value.data = item;

      setTimeout(() => {
        (document.getElementById("open-modal-detail") as HTMLElement).click();
      }, 300);
    };

    //Form submit function
    const FormSubmit = () => {
      if (inputButton.value) {
        // Activate indicator
        inputButton.value.setAttribute("data-kt-indicator", "on");
      }

      const val = document.getElementById("form-input") as HTMLFormElement;
      const data = new FormData(val);

      // console.log(data);

      // Dummy delay
      setTimeout(() => {
        // Send request
        store
          .dispatch("store" + name, data)
          .then(() => {
            Swal.fire({
              text: store.state.ProfilModule.msg,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              // Go to page after successfully
              // goBack();
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.state.ProfilModule.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        inputButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };
    const FormUpdate = () => {
      if (editButton.value) {
        // Activate indicator
        editButton.value.setAttribute("data-kt-indicator", "on");
      }

      const val = document.getElementById("form-edit") as HTMLFormElement;
      const data = new FormData(val);

      // console.log(data);

      // Dummy delay
      setTimeout(() => {
        // Send request
        store
          .dispatch("store" + name, data)
          .then(() => {
            Swal.fire({
              text: store.state.ProfilModule.msg,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              // Go to page after successfully
              // goBack();
            });
          })
          .catch(() => {
            Swal.fire({
              text: store.state.ProfilModule.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        editButton.value?.removeAttribute("data-kt-indicator");
      }, 1000);
    };

    const changeFoto = (el) => {
      const category = el.target.id;
      if (category == "input-preview") {
        (document.getElementById("input-foto") as HTMLElement).click();
      } else if (category == "update-preview") {
        (document.getElementById("update-foto") as HTMLElement).click();
      }
    };
    const preFoto = (el) => {
      const category = el.target.id;
      if (el.target.value) {
        const file = el.target.files[0];
        if (category == "input-foto") {
          created.value.newFoto = URL.createObjectURL(file);
          created.value.logo = el.target.files[0].name;
        } else if (category == "update-foto") {
          detail.value.newFoto = URL.createObjectURL(file);
          detail.value.logo = el.target.files[0].name;
        }
      } else {
        if (category == "input-foto") {
          created.value.newFoto = "";
          created.value.logo = "";
        } else if (category == "update-foto") {
          detail.value.newFoto = "";
          detail.value.logo = "";
        }
      }
    };
    const changeLimit = (el) => {
      const val = el.target.value;
      store.commit("SET_LIMIT_" + name.toUpperCase(), val);
      store.dispatch("All" + name);
    };
    const getRows = (val) => {
      store.dispatch("All" + name).then(() => {
        store.commit("SET_PAGE_" + name.toUpperCase(), val);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    };
    const destroyData = (item) => {
      Swal.fire({
        html: `<b>${title} ${item.name}</b>, Yakin dihapus?`,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Hapus!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
        showLoaderOnConfirm: true,
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch("destroy" + name, item.id)
            .then(() => {
              Swal.fire("Terhapus!", `${title} berhasil dihapus.`, "success");
            })
            .catch(() => {
              Swal.fire(store.state.ProfilModule.error, "success");
            });
        }
      });
    };
    const resetData = () => {
      changeLimit({ target: { value: 10 } });
      getRows(1);
      store.dispatch("All" + name);
    };

    return {
      title,
      page,
      limit,
      totalPage,
      data,
      detail,
      path,
      getDetail,
      destroyData,
      resetData,
      form,
      formEdit,
      foto,
      FormSubmit,
      FormUpdate,
      changeFoto,
      preFoto,
      created,
      changeLimit,
      getRows,
      inputButton,
      editButton,
    };
  },
});
</script>
